import React from 'react'

const About = () => {
    return (
        <section className="flex justify-center items-center p-8 h- mt-40 md:h-[500px]" id='about'>
            <div className="flex justify-center items-center  p-8 h-auto">
                <div className="flex flex-col justify-center">
                    <h1 className='text-orange-500 uppercase text-3xl font-bold font-Roboto mb-5'>about us</h1>
                    <div className=" w-full md:max-w-7xl">
                        <div className="flex flex-col text-black md:items-start items-center justify-between space-y-3 px-8">
                            <div className="text-sm md:text-xl md:leading-10 md:tracking-wide">
                                Roofing Maintenance have a vast plethora of knowledge spanning over 40+ yrs. With most of our staff having experienced over 25yrs in the industry, you are in safe hands.
                                Our approach is simple “Be the best!” With this said, we are ever-adapting to new techniques, products, specifications and training that’s available for our staff within our industry, always remaining on-par with advancing technologies. We are proud to be accredited with some of the best well known industry bodies, thus giving you peace of mind when choosing our company for any of your roofing requirements.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
