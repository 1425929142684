import React from 'react'
import Navbar from './Navbar'
import Home from './Home'
import About from './About'
import Accredations from './Accredations'
import Services from './Services'
import Maintenance from './Maintenance'
import Footer from './Footer'

const Sections = () => {
  return (
    <div>
      <Navbar/>
      <Home/>
      <About/>
      <Accredations/>
      <Services/>
      <Maintenance/>
      <Footer/>
    </div>
  )
}

export default Sections
