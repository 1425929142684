import React from 'react'
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import "../../css/home.css";

const Navbar = () => {
  return (
    <div>
      <div className='bg-orange-400 text-white flex justify-around  items-center h-16 text-animate'>
        <p><span className='font-bold text-xl'>Dec 22: </span>Roofing Tile Replacement</p>
        <p><span className='font-bold text-xl'>Dec 22: </span> Tile to Slate Replacement</p>
      </div>
      <div className='bg-black text-white text-xl flex justify-around  items-center h-14'>
        <div>
          <p>info@beddardroofing.co.uk</p>
        </div>
        <div className='flex'>
          <AiOutlineFacebook className='m-2 h-8 w-8' />
          <AiOutlineInstagram className='m-2 h-8 w-8' />
        </div>
      </div>
      <header className="text-gray-600 body-font bg-neutral-700 text-white h-1/6">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div className="flex flex-col title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <img src='./logo.jpg' alt='logo' className="rounded-xl h-10 w-20" />
            <span className="ml-3 text-sm text-white">Beddard Roofing Ltd</span>
          </div>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 flex flex-wrap items-center text-base justify-center">
            <a className="mr-5 hover:text-orange-100">Home</a>
            <a className="mr-5 hover:text-orange-100" href='#about'>About US</a>
            <a className="mr-5 hover:text-orange-100" href='#services'>Services</a>
            <a className="mr-5 hover:text-orange-100">FAQ's</a>
            <a className="mr-5 hover:text-orange-100">Case Study</a>
            <a className="mr-5 hover:text-orange-100">Projects</a>
            <a className="mr-5 hover:text-orange-100" href='#footer'>Contact Us</a>
          </nav>
        </div>
      </header>
    </div>
  )
}

export default Navbar
