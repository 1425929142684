import React from 'react'

const Home = () => {
    return (
        <section className="text-black body-font">
            <div className="rounded-lg h-[200px] md:h-2/3 overflow-hidden">
                <img alt="content" className="object-center h-full w-full" src="./roof.jpg" />
            </div>
                <div className="flex justify-center items-center bg-neutral-900 text-white p-8 md:h-auto">
                    <div className="flex flex-col justify-center">
                        <div className=" w-full max-w-7xl">
                            <div className="flex flex-col text-black md:items-start items-center justify-between space-y-3 px-8">
                                <div className="text-xs md:text-xl md:leading-10 md:tracking-wide text-white">
                                    Our highly trained team at Roofingmaintence.co.uk are experts in all aspects of roofing from Welsh & Spanish slate, stone, large and small tile products. Our skill set doesn’t stop there! We also specialise in lead and flat roofing from traditional methods to current products like fibreglass and rubber membranes. Our services range from complete re-roofing, dormers, Velux window fitting, rainwater goods to slipped tiles, small repairs and ridge tile cement work. We are proud to be the only accredited roofing company in Greater Manchester and Cheshire to offer all the services needed to cover any request for your roof like our vastly popular ‘Roofing Maintenance Packages’. These packages are available to anyone whether you’re an existing customer of Roofing maintenance.co.uk. or yet take advantage of our services. These are a great way to give you peace of mind throughout the year, safegaurding from what our local weather and climate may throw at your property knowing we have you covered through a plan should a problem arise.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Home
