import React from 'react'

const Accredations = () => {
    return (
        <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap w-full mb-20">
                <div className="lg:w-1/5 w-full mb-6 lg:mb-0">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-orange-500">Accredations</h1>
                    <div className="h-1 w-44 bg-orange-500 rounded"></div>
                </div>
            </div>
            <div className="flex flex-wrap -m-4">
                <div className="xl:w-1/5 md:w-1/2 p-4">
                    <div className="bg-orange-100 p-6 rounded-lg">
                        <img className="h-40  rounded w-full object-cover object-center mb-6" src="./nfrc.jpg" alt="content" />
                        <h3 className="tracking-widest text-orange-500 text-xs font-medium title-font">Accredations I</h3>
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4">The National Federation of Roofing Contractors-</h2>
                        <p className="leading-relaxed text-base">Being the UK’s largest roofing trade association and spanning over 125 years, our recognition by such a distinguished association ensures complete protection of your relationship with Roofing Maintenance. Our conscientious workmanship alongside such accreditations carries a promise to meet the highest of standards, quote fairly on each occasion and provide technical support before, during, and after all services rendered.</p>
                    </div>
                </div>
                <div className="xl:w-1/5 md:w-1/2 p-4">
                    <div className="bg-orange-100 p-6 rounded-lg">
                        <img className="h-40 rounded w-full object-cover object-center mb-6" src="./crs.jpg" alt="content" />
                        <h3 className="tracking-widest text-orange-500 text-xs font-medium title-font">Accredations II</h3>
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Competent Roofer Scheme </h2>
                        <p className="leading-relaxed text-base">This highly prestigious accreditation affords roofing maintenance the enviable ability to sign off our own workmanship thanks to the time served trust gained to adhere to stringent building regulations. This acknowledgement is provided to the most vetted and trusted of tradesmen. </p>
                    </div>
                </div>
                <div className="xl:w-1/5 md:w-1/2 p-4">
                    <div className="bg-orange-100 p-6 rounded-lg">
                        <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/722x402" alt="content" />
                        <h3 className="tracking-widest text-orange-500 text-xs font-medium title-font">Accredations III</h3>
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Velux</h2>
                        <p className="leading-relaxed text-base">As 5 Star rated certified installers of Velux, we are proud to be able to offer all our existing and future clients any of the products Velux have to offer, fitted to the highest standards and carrying a fully backed guarantee.</p>
                    </div>
                </div>
                <div className="xl:w-1/5 md:w-1/2 p-4">
                    <div className="bg-orange-100 p-6 rounded-lg">
                        <img className="h-52 rounded w-full object-cover object-center mb-6" src="./trustmark.jpg" alt="content" />
                        <h3 className="tracking-widest text-orange-500 text-xs font-medium title-font">Accredations IV</h3>
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Trustmark</h2>
                        <p className="leading-relaxed text-base">This accreditation is gained through due diligence covering on-site inspections, customer records, required standards and financial checks. A Trustmark registered business gives assurance by a government backed scheme ensuring commitment to good customer service, technical competence and trading practice. Such vetting gives clients insurance backed warranties, deposit protection insurance and adhesion to Trustmark’s code of practice.</p>
                    </div>
                </div>
                <div className="xl:w-1/5 md:w-1/2 p-4">
                    <div className="bg-orange-100 p-6 rounded-lg">
                        <img className="h-52 rounded w-full object-cover object-center mb-6" src="./fair traders.jpg" alt="content" />
                        <h3 className="tracking-widest text-orange-500 text-xs font-medium title-font">Accredations IV</h3>
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Fair Traders</h2>
                        <p className="leading-relaxed text-base">Compliance to the Fairtrades accreditation ensures we follow the most stringent code of conduct which this distinguished body demands.
                            Such code of conduct includes full transparency with all supplied contracts, written quotations in advance, never forcefully gaining client signatures, compliance of all laws and building regulations to the letter, punctual approach, respect of clients privacy and rights, completion in allocated time frames, communication at all times of any unexpected changes, safe, clean and tidy results on completion and written guarantees on all services.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    )
}

export default Accredations
