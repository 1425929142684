import React from 'react'
import { AiOutlineMail, AiOutlinePhone, AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";

const Footer = () => {
    return (
        <footer className="text-white body-font bg-neutral-800" id='footer'>
            <div className="container py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                    <div className="flex flex-col title-font font-medium items-center md:justify-start justify-center text-gray-900">
                        <img src='./logo.jpg' alt='logo' className="rounded-xl h-10 w-20" />
                        <span className="ml-3 text-sm text-white">Beddard Roofing Ltd</span>
                        <span className="ml-3 text-xs  italic text-white">Quality comes as Standard</span>
                    </div>
                </div>
                <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                    <div className="lg:w-1/2 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-purple-200 tracking-widest text-3xl mb-3 font-Poppins">Beddard Roofing Ltd</h2>
                        <p className="mt-2 text-lg text-white hover:text-orange-800 tracking-wider">7 Ricroft Road, Compstall, Cheshire SK6 8JR</p>
                        <div className='flex justify-center md:justify-start'>
                            <AiOutlineFacebook className='bg-orange-800 w-16 h-10 rounded-3xl m-2' />
                            <AiOutlineInstagram className='bg-orange-800 w-16 h-10 rounded-3xl m-2' />
                        </div>
                    </div>
                    <div className="lg:w-1/2 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-purple-200 tracking-widest text-xl mb-3 uppercase">contact us</h2>
                        <nav className="list-none mb-10">
                            <li className='flex items-center m-3'>
                                <AiOutlineMail className='bg-orange-800 w-16 h-10 rounded-3xl' />
                                <a className="text-white hover:text-orange-800 mx-5">
                                    info@beddardroofing.co.uk</a>
                            </li>
                            <li className='flex items-center m-3'>
                                <AiOutlinePhone className='bg-orange-800 w-16 h-10 rounded-3xl' />
                                <a className="text-white hover:text-orange-800 mx-5">0161 427 6338</a>
                            </li>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="bg-orange-500 text-white text-center">
                <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row ">
                    <p className="text-white text-sm sm:text-left">© 2023 Company Name </p>

                </div>
            </div>
        </footer>
    )
}

export default Footer
