import React from 'react'
import { MdOutlineRoofing } from "react-icons/md";
import { AiOutlineTool } from "react-icons/ai";

const Services = () => {
    return (
        <section className="text-gray-600 body-font overflow-hidden" id="services">
            <div className="container px-5 py-5 mx-auto">
                <div className="flex flex-wrap w-full mb-20">
                    <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-orange-500">Services</h1>
                        <div className="h-1 w-44 bg-orange-500 rounded"></div>
                    </div>
                </div>
                <div className="my-8 h-auto p-2 mx-auto flex flex-col  md:flex-row">
                        <img alt="nfrc" className="lg:w-1/2 w-full lg:h-full h-40 animate__animate__slideInLeft" src="./work.jpg" />
                    <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 ">
                        <ul>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-bold title-font text-black text-2xl">Tile roofing </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Come in various shapes and sizes and are a cheaper alternative to natural products. Unlike natural products tiles are a good choice of anyone who want to keep things uniform. </p>
                                </div>
                            </li>
                            <li className='my-3'>
                                <div className='flex'>
                                    <AiOutlineTool className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">Slate roofing </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">Stone Roofing </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="my-8 h-auto p-2 mx-auto flex flex-wrap md:flex-row ">
                    <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                        <ul>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">Flat Roofing </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">Guttering and Downspouts</span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">Chimney stack removals </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                        <img alt="Competent Roofer Scheme" className="lg:w-1/2 w-full lg:h-full h-64 rounded" src="./worktwo.jpg" />
                </div>
                <div className="my-8 h-auto p-2 mx-auto flex flex-wrap flex-col flex-wrap md:flex-row">
                    <img alt="Velux" className="lg:w-1/2 w-full lg:h-full h-64 rounded" src="./Roof-Maintenance.jpg" />
                    <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                        <ul>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">Chimney stack repairs </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">General maintenance </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl' />
                                    <span className="font-semibold title-font text-black text-2xl mx-2">Velux Installation </span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="my-8 h-auto p-2 mx-auto flex flex-wrap flex-col md:flex-row">
                    <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                        <ul>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">New Roofs</span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer.</p>
                                </div>
                            </li>
                            <li className='my-3'>
                                <div className='flex'>
                                    <MdOutlineRoofing className='bg-orange-600 text-white w-10 h-10 rounded-3xl mx-2' />
                                    <span className="font-semibold title-font text-black text-2xl">Flat to pitched roof conversions</span>
                                </div>
                                <div className="md:flex-grow">
                                    <p className="leading-relaxed text-xl">There are a whole host of benefits to converting your flat roof to a pitch roof. Not only can it be more aesthetically pleasing to owners but more appealing to potential buyers. The extra space gained maybe used for additional storage or an extra room creating more kerb appeal and potential value to your property. Our solutions cater for all aspect’s choice relating to pitched roof conversions. </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-full h-64 rounded" src="./ttol.jpg" />
                </div>
            </div>
        </section >
    )
}

export default Services
