import Sections from "./components/Sections";

function App() {
  return (
    <div className="App">
      <Sections/>
    </div>
  );
}

export default App;
