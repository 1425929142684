import React from 'react'

const Maintenance = () => {
    return (
        <div class="flex items-center justify-center bg-gradient-to-r from-white to-orange-500 ">
            <div class="flex-col items-center justify-center text-black">
                <div class="h-40 w-[70%] md:w-1/2 flex items-center justify-center  text-3xl lg:text-5xl font-bold mx-14">Maintenance</div>
                <div class="flex">
                    <div class="flex md:flex-row flex-col p-4 space-x-4 space-y-2 max-w-7xl justify-around w-full h-auto lg:h-60 items-center ">
                        <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center ml-4">
                            <div class="flex-col space-y-2 items-center px-0 md:px-6">
                                <div class="text-sm font-medium text-gray-600 text-center">Maintenance</div>
                                <div class="text-3xl font-bold">34pence/day</div>
                                <div class="text-sm font-medium text-gray-600">
                                    We offer a FREE full assessment providing images and reports across all packages.
                                    Please consider the options below to find which best suits your budget and needs.
                                </div>
                            </div>
                        </div>
                        <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center">
                            <div class="flex-col space-y-2">
                                <div class="text-sm font-medium text-gray-600">Economy</div>
                                <div class="text-5xl font-bold">£pa </div>
                                <div class="text-sm font-medium text-gray-600">Clear leaves and debris from guttering outlets and down pipes. External Cleaning of guttering, reseal any jointing if required. Check and re-attach fixings if necessary </div>
                            </div>
                        </div>
                        <div class="h-40 w-[70%] md:w-1/4 flex items-center justify-center">
                            <div class="flex-col space-y-2">
                                <div class="text-sm font-medium text-gray-600">Premium</div>
                                <div class="text-5xl font-bold">£</div>
                                <div class="text-sm font-medium text-gray-600">PA or as little as 0.00 pd </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Maintenance
